import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import BlogPost from "../../components/BlogPost"
import Layout from "../../components/Layout"
import { Content, H1, H2, P } from "../../styles"
import 'react-spring-bottom-sheet/dist/style.css'
import MobileWrapper from "../../components/MobileWrapper"
import { isEqual, uniqWith } from "lodash"

const IGNORED_IDS = ['14dPgUbCSGjL4ppiH9Kms0'];

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            id: contentful_id
            title
            text {
              raw
            }
            createdAt
          }
        }
      }
    }
`);

  return (
    <Layout>
      <MobileWrapper>
        <StaticImage
          placeholder="blurred"
          src="../../images/blog.png"
          alt="home image"
          style={{ maxWidth: '50%', height: 'auto', position: 'absolute', right: 96 }}
        />
      </MobileWrapper>
      <Content>
        <H1 animation>
          Blog
        </H1>

        <H2 animation>
          Posts
        </H2>
        {uniqWith(data?.allContentfulBlogPost?.edges, isEqual)?.map((edge: any) => 
          !IGNORED_IDS.includes(edge?.node?.id) ? <BlogPost
            id={edge?.node?.id}
            text={edge?.node?.text}
            title={edge?.node?.title}
            createdAt={edge?.node?.createdAt}
          /> : null
        )}
      </Content>
    </Layout>
  )
}

export default BlogPage;
