import dayjs from 'dayjs';
import * as React from 'react';
import { BlogParagraph, BlogPostContainer } from './styles';

type BlogPostParms = {
    id: string;
    title: string;
    text: {
        raw: string;
    };
    createdAt: string;
}

const BlogPost = ({ id, title, text, createdAt }: BlogPostParms) => {
    const postText = JSON.parse(text.raw);

    return <BlogPostContainer to={`${id}`}>
        <span>{dayjs(createdAt).format('DD/MM/YYYY')}</span>
        <h2>{title}</h2>
        <BlogParagraph>{postText.content?.[0]?.content?.[0]?.value ?? ''}</BlogParagraph>
    </BlogPostContainer>
}

export default BlogPost;
